function initFileUploader() {
    $('.input-fileupload').each(function(index, node){
        var $box = $(node).parents(".file-upload-box");
        var $progress = $box.find(".progress-bar");

        $(node).fileupload({
            dataType: 'json',
            done: function (e, data) {
                var $filesJsonInput = $box.find("[name=filesJson]");
                var filesJson = $filesJsonInput.val();

                if(filesJson == "") filesJson = {files:[]};
                else filesJson = jQuery.parseJSON(filesJson);

                $.each(data.result.files, function (index, file) {
                    if(file.error != undefined) {
                        return;
                    }

                    var list = $box.find(".list");
                    var img = $('<img/>').attr('src', file.thumbnailUrl);
                    img.attr('title','Загрузка файлов');
                    var par = $('<p/>');

                    par.click(function(){
                        var jsStr = $box.find("[name=filesJson]").val();
                        var jsObj = jQuery.parseJSON(jsStr);

                        for(var i = 0; i < jsObj.files.length; i++) {
                            if(jsObj.files[i].url == file.url)
                                jsObj.files.splice(i, 1);

                        }

                        $box.find("[name=filesJson]").val(JSON.stringify(jsObj));
                        $(this).remove();
                    });

                    img.appendTo(par);
                    par.appendTo(list);
                    filesJson.files.push(file);
                });

                $filesJsonInput.val(JSON.stringify(filesJson));
            },
            progressall: function (e, data) {
                var progress = parseInt(data.loaded / data.total * 100, 10);
                $progress.css('width', progress + '%');
                $progress.find("span").text(progress + '%');
            }
        });
    });

    $(".file-upload-box .list p").click(function() {
        var $box = $(this).parents(".file-upload-box");
        var jsStr = $box.find("[name=filesJson]").val();
        var jsObj = jQuery.parseJSON(jsStr);
        var img = $(this).find("img");

        for(var i = 0; i < jsObj.files.length; i++) {
            if(jsObj.files[i].thumbnailUrl == $(img).attr("src"))
                jsObj.files.splice(i, 1);
        }

        $box.find("[name=filesJson]").val(JSON.stringify(jsObj));
        $(this).remove();
    });
}

$(function() {

    $(".wys").editable({
        inlineMode: false,
        editorClass: "textual-content",
        imageUploadURL: '/upload_image.php',
        imageUploadParams: {id: "my_editor"}
    });
    $("#gallery").justifiedGallery({captions:false});
    $('.swipebox').swipebox({useSVG : false});

    initFileUploader();

    var $bgobj = $("body");

    $(window).scroll(function(event) {
        var bgSize = 2185 - $(window).height();
        var s = $(window).scrollTop(),
            d = $(document).height(),
            c = $(window).height();

        var scrollPercent = (s / (d-c)) * 100;
        var yPos = -1 * (bgSize * scrollPercent / 100);
        var coords = 'center '+ yPos + 'px';

        console.log(coords);

        $bgobj.css({ backgroundPosition: coords });
    });
});